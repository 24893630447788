export function FormatCardNumber(value) {
  const v = value
    .replace(/\s+/g, "")
    .replace(/[^0-9]/gi, "")
    .substr(0, 16);
  const parts = [];

  for (let i = 0; i < v.length; i += 4) {
    parts.push(v.substr(i, 4));
  }

  return parts.length > 1 ? parts.join(" ") : value;
}

export const compare = (a, b) => {
  // a and b are arrays of the form [key, value]
  // we want to compare the value.name property, which is a string
  const nameA = a[1].name.toUpperCase(); // ignore case
  const nameB = b[1].name.toUpperCase(); // ignore case
  if (nameA < nameB) {
    return -1; // nameA comes first
  }
  if (nameA > nameB) {
    return 1; // nameB comes first
  }
  return 0; // names are equal
};

export const addressRender = (dict) => {
  const change = (element) => {
    const addr = `${element.street}\n${element.city}${element.state === '' ? '' : '\n'+element.state}, ${element.postalCode}\n${element.country}`
    return addr
  };
  
  // Apply the function to each element in the array and create a new array
  return dict.map(change);
};
