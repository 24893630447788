import React, {useEffect} from 'react'

const AboutUs = () => {
  useEffect(() => {
    document.title = "About Us";
    window.scrollTo(0,0)
  }, []);

  return (
    <header class="bg-dark py-5">
        <div class="container px-5">
            <div class="row gx-5 justify-content-center">
                <div class="col-lg-6">
                    <div class="text-center my-5">
                        <h1 class="display-5 fw-bolder text-white mb-2">About Us</h1>
                        <p class="lead text-white mb-4 mt-5">Welcome to Teskill Technologies, your number one source for
                            all things New Technologies. We're dedicated to providing you the very best of Service, with
                            an
                            emphasis on Problem solving.

                        </p>
                        <p class="lead text-white-50 mb-4">
                            Founded in 2020 by Dimal Jay, Teskill Technologies has come a long way from its
                            beginnings in Sri Lanka. When Dimal Jay first started out, His
                            passion for Computer Technology drove them to start
                            their own business.
                        </p>
                        <p class="lead text-white-50 mb-4">
                            We hope you enjoy our products as much as we enjoy offering them to you. If you have any
                            questions or comments, please don't hesitate to contact us.
                        </p>

                        <p class="lead text-white mb-5 fw-bold">
                            Sincerely,<br/>

                            Dimal Jay</p>

                    </div>
                </div>
            </div>
        </div>
    </header>
  )
}

export default AboutUs