import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { products } from "../../config/products"
import "bootstrap/dist/css/bootstrap.min.css"
import styled from 'styled-components';
import { pages } from '../../config/rootpage';

const Promote = styled.button`
  background-color: red;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border: none;
  border-radius: 10px;
  text-align: center;
  color: white;
  transition-duration: 100ms;
  transition-timing-function: ease-out;
  &:hover {
    background-color: #8b0000;
    transition-duration: 100ms;
    transition-timing-function: ease-in;
  }
`

const Header = ({headerTheme}) => {
  
  return (
    <Navbar bg={headerTheme} variant={headerTheme} expand="lg">
      <Container>
        <Navbar.Brand href="/">
            <img src="/android-chrome-192x192.png" class="me-3" width="30" height="30" alt=""/>
            Unique Name Generator
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
          <Nav className="ms-auto mb-2 mb-lg-0">
            <Nav.Link href={pages.card_validator.slug} ><Promote>Free Credit Card Validator</Promote></Nav.Link>
            <Nav.Link href="/">Home</Nav.Link>
            
            <NavDropdown title="Tools" id="basic-nav-dropdown">
            {products.map(nav => (
                <NavDropdown.Item href={nav.action}>{nav.name}</NavDropdown.Item>
            ))}
            
            </NavDropdown>
            <Nav.Link href={pages.faq.slug}>FAQ</Nav.Link>
            <Nav.Link href={pages.about.slug}>About</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header