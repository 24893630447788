import { useEffect, useState } from 'react'
import PageHeader from '../../../components/Header/PageHeader'
import FilterDOM from '../../../components/FilterDOM'
import { ToastContainer } from 'react-toastify';
import NameGridItem from './NameGridItem';
import { BsPersonCircle } from 'react-icons/bs'
import { config } from '../../../config/constants';
import { products } from '../../../config/products';

const NameGeneratorLayout = ({ type }) => {
  const [gridData, setData] = useState([]);
  const [isLoading, setLoadingState] = useState(false);

  const getGenderTag = (type) => products.find((product) => product.action === type).tag;

  const sendRequest = async (max = 50) => {
    setLoadingState(true);
    const requestBody = {
      gender: getGenderTag(type),
      max: max,
    }
    fetch(`${config.api_url}/person`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'same-origin',
      body: JSON.stringify(requestBody)

    }).then((res) =>
      res.json().then((data) => {
        setData(data);
        setLoadingState(false)
      })
    );
  }

  useEffect(() => {
    document.title = "Free Name Generator";
    sendRequest();
  },[])

  function onOptionChange(optionValue) {
    sendRequest(optionValue);
  }

  function gridItem(item) {

    return (
      <NameGridItem name={item} icon={<BsPersonCircle size={24} />} />
    );
  }

  return (
    <div>
      <PageHeader tool={type} />
      <FilterDOM data={gridData} gridItem={gridItem} onOptionChange={onOptionChange} isLoading={isLoading} />
      <ToastContainer />
    </div>
  )
}

export default NameGeneratorLayout