import { Outlet,useLocation  } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer"
import { ThemeProvider } from 'styled-components'
import { lightTheme } from '../styles/theme' 
import { GlobalStyles } from '../styles/global'
import LandingHeader from "../components/Header/LandingHeader";
import { pages } from "../config/rootpage";
import { HelmetProvider } from "react-helmet-async";

const Layout = () => {
  
  const location = useLocation();
  const isLanding = location.pathname === "/"

  function isDarkHeader(){
    switch (location.pathname) {
      case "/":
        return true
      case pages.about.slug:
        return true
      case pages.faq.slug:
        return true
      case pages.privacy_policy.slug:
        return true
      case pages.contact.slug:
        return true
      case pages.card_validator.slug :
        return true;
      default:
        return false
    }
  }

  return (
    <>
    <HelmetProvider>
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles />
        <Header headerTheme={isDarkHeader() ? 'dark' : 'light'} />
        {isLanding ? <LandingHeader/> : <></>}
        
        <Outlet />
        <Footer />
      </ThemeProvider>
      </HelmetProvider>
    </>
  )
};

export default Layout;