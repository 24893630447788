import { Container, Row, Col } from 'react-bootstrap';


function GridView(props) {
  return (
    <Container fluid>
      <Row xs={1} md={2} lg={4} className="g-4">
        {props.items.map(item => (
          <Col >
            {props.gridItem(item)}
          </Col>

        ))}
      </Row>
    </Container>
  );
}

export default GridView;
