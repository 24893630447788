import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./page/Layout";
import Contact from "./page/Contact";
import AboutUs from "./page/AboutUs";
import Home from "./page/Home/Home";
import Error404 from "./page/Error404"
import NameGeneratorLayout from "./page/tools/NameGenerator/NameGeneratorLayout";
import CardGenerator from "./page/tools/CardGenerator/CardGenerator";
import AddressGenerator from "./page/tools/AddressGenerator/AddressGenerator";
import TextGenerator from "./page/tools/TextGenerator/TextGenerator";
import BusinessNameGenerator from "./page/tools/BusinessNameGenerator/BusinessNameGenerator";
import CardValidator from "./page/tools/CardValidator/CardValidator";
import FAQPage from "./page/FAQPage";
import PrivacyPolicyPage from "./page/PrivacyPolicyPage";
import { pages } from "./config/rootpage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path={pages.about.slug} element={<AboutUs />} />
          <Route path={pages.faq.slug} element={<FAQPage />} />
          <Route path={pages.privacy_policy.slug} element={<PrivacyPolicyPage />} />
          <Route path={pages.contact.slug} element={<Contact />} />
          {/* <Route path="*" element={<Navigate to="/404"/>} /> */}
          <Route path="/404" element={<Error404/>} />
          <Route path={pages.name_generator.slug} element={<NameGeneratorLayout type={pages.name_generator.slug} />} />
          <Route path={pages.male_name_generator.slug} element={<NameGeneratorLayout type={pages.male_name_generator.slug} />} />
          <Route path={pages.female_name_generator.slug} element={<NameGeneratorLayout type={pages.female_name_generator.slug}/>} />
          <Route path={pages.address_generator.slug} element={<AddressGenerator type={pages.address_generator.slug} />} />
          <Route path={pages.business_name_generator.slug} element={<BusinessNameGenerator type={pages.business_name_generator.slug} />} />
          <Route path={pages.card_generator.slug} element={<CardGenerator type={pages.card_generator.slug} />} />
          <Route path={pages.text_generator.slug} element={<TextGenerator type={pages.text_generator.slug} />} />
          <Route path={pages.card_validator.slug} element={<CardValidator />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
