import React, { useEffect, useState } from "react";
import { Form, Row, Col, Alert, Button, Spinner, ListGroup , Container} from "react-bootstrap";
import "./card_style.css";
import { FormatCardNumber } from "../../../functions/functions";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Center = styled.div`
  justify-content: center;
  display: flex;
  padding: 1rem;
`;

const CardValidAlert = (props) => {
    const properties = Object.entries({
        "Card Name": props.scheme.charAt(0).toUpperCase() + props.scheme.slice(1),
        "Card Type": props.type,
        "Card Brand":props.brand,
        "Prepaid": props.prepaid ? "Yes" : "No",
        "Country": props.country !== undefined ? `${props.country.name} (${props.country.emoji})` : undefined,
        "Currency": props.country !== undefined ? props.country.currency : undefined,
        "Bank Name": props.bank !== undefined ? props.bank.name : undefined,
        "Bank Website": props.bank !== undefined ? <Link to={`https://${props.bank.url}`} target="_blank">{props.bank.url}</Link> : undefined,
        "Bank Contact": props.bank !== undefined ? <Link to={`tel:${props.bank.phone}`}>{props.bank.phone}</Link> : undefined,
        "Bank City":props.bank !== undefined ? props.bank.city : undefined,
    });
  return (
    <>
      <ListGroup>
      {properties.map(([key, value]) => (
        
        <ListGroup.Item key={key}><Row><Col>{key}</Col><Col>{value===undefined || value===null ? <i>Not Found</i>: <strong>{value}</strong>}</Col></Row></ListGroup.Item>
      ))}
    </ListGroup>
    </>
  );
};
const CardValidator = () => {
  const [cardNumber, setCardNumber] = useState("");
  const [cardDetails, setCardDetails] = useState({});
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [cardValid, setCardValid] = useState(false)

  useEffect(() => {
    document.title = "Credit Card Validator";
    window.scrollTo(0,0)
  }, []);


  const handleSubmit = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(true);
      event.preventDefault();
      validateCardNumber(cardNumber);
    }
  };

  const validateCardNumber = (card) => {
    setLoading(true);
    const card_first_8_digits = card.replace(/ /g, "").slice(0, 8);
    console.log(card_first_8_digits);
    fetch(`https://lookup.binlist.net/${card_first_8_digits}`, {
      method: "GET",
      headers: { "Content-Type": "application/json", "Accept-Version": 3 },
      credentials: 'same-origin',
    })
      .then((res) =>
        res.json().then((data) => {
          setCardDetails(data);
          setShowAlert(true);
          setCardValid(true)
          setLoading(false);
        })
      )
      .catch((err) => {
        console.log("err");
        setCardValid(false)
        setLoading(false);
        setCardDetails({})
        setCardNumber("")
      });
  };

  const handleCardNumberChange = (e) => {
    setCardNumber(e.target.value);
  };

  return (
    <>
    <header className="bg-dark py-5">
        <Container>
          <Row className="gx-5 justify-content-center">
            <Col lg={6}>
              <div className="text-center my-5">
                <h1 className="display-5 fw-bolder text-white mb-2">Credit/ Debit Card Validator Tool</h1>
                <p className="lead text-white-50 mb-4">Validate and Scan Your Credit Card</p>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

   
    <div className="py-5 px-3">
      <Row>
        <div className="container-fluid d-flex justify-content-center">
          <Col xs={12} md={8} lg={6}>
            <div className="card payment-card">
              <div className="card-header">
                <Row>
                  <div className="col-md-6">
                    <span>CREDIT/DEBIT CARD VALIDATOR</span>
                  </div>
                  {/* <div
                    className="col-md-6 text-right d-flex justify-content-end"
                    style={{ marginTop: "-5px" }}
                  >
                    <img
                      src="https://img.icons8.com/color/36/000000/visa.png"
                      alt="Visa"
                    />
                    <img
                      src="https://img.icons8.com/color/36/000000/mastercard.png"
                      alt="Mastercard"
                    />
                    <img
                      src="https://img.icons8.com/color/36/000000/amex.png"
                      alt="American Express"
                    />
                  </div> */}
                </Row>
              </div>
              <div className="card-body" style={{ height: "auto" }}>
                <Form noValidate validated={validated}>
                  <Form.Group controlId="formCardNumber">
                    <Form.Label>CARD NUMBER</Form.Label>
                    <Form.Control
                      type="tel"
                      className="input-lg form-control-lg cc-number"
                      size="lg"
                      autoComplete="cc-number"
                      placeholder="&bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;"
                      required
                      //   pattern="[0-9]{16}"
                      value={FormatCardNumber(cardNumber)}
                      onChange={handleCardNumberChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid 16-digit card number.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formSubmit">
                    <Button
                      onClick={handleSubmit}
                      type="button"
                      formNoValidate
                      value="VALIDATE CARD"
                      variant="success"
                      disabled={isLoading}
                      className="btn btn-success btn-lg form-control mt-3"
                      style={{ fontSize: ".8rem" }}
                      id="verify-card"
                    >
                      {isLoading ? (
                        <>VALIDATING ...</>
                      ) : (
                        <span>VALIDATE CARD</span>
                      )}
                    </Button>
                  </Form.Group>

                  {isLoading ? (
                    <Center>
                      <Spinner animation="grow" />
                    </Center>
                  ) : showAlert ? (<>
                    <Alert
                      variant={cardValid ? "success" : "danger"}
                      className="mt-3"
                    >
                      {cardValid ? "🎉 Congratulations! Your card is valid and ready to go. Enjoy the freedom to shop and make payments with ease." : "😓 Oh no! It seems like there was an issue with your card. Please check your details and try again."}
                    </Alert>
                     {cardValid ? CardValidAlert(cardDetails) : <></>}
                    </>
                  ) : (
                    <></>
                  )}
                </Form>
              </div>
            </div>
          </Col>
        </div>
      </Row>
    </div>
    </>
  );
};

export default CardValidator;
