import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/Header/PageHeader";
import { ToastContainer } from "react-toastify";
import CreditCard from "./CreaditCard";
import FilterDom from "../../../components/FilterDOM";
import { config } from "../../../config/constants";
import Description from "./Description";

const CardGenerator = ({ type }) => {
  const [cards, setCards] = useState([]);
  const [isLoading, setLoadingState] = useState(false);

  const sendRequest = async (max = 15) => {
    setLoadingState(true);
    const requestBody = {
      max: max,
    };
    fetch(`${config.api_url}/credit-card`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: 'same-origin',
      body: JSON.stringify(requestBody),
    }).then((res) =>
      res.json().then((data) => {
        setCards(data);
        setLoadingState(false);
      })
    );
  };

  useEffect(() => {
    sendRequest();
    document.title = "Free Credit Card Generator";
  }, []);

  function onOptionChange(optionValue) {
    sendRequest(optionValue);
  }

  function gridItem(item) {
    return <CreditCard props={item} />;
  }
  return (
    <>
      <PageHeader tool={type} />

      <FilterDom
        data={cards}
        gridItem={gridItem}
        onOptionChange={onOptionChange}
        isLoading={isLoading}
        dropdown={[15]}
      />
      <Description/>
      <ToastContainer />
    </>
  );
};

export default CardGenerator;
