import { useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

function Contact() {
  useEffect(() => {
    document.title = "Contact - Unique Name Generator";
    window.scrollTo(0,0)
  }, []);

  return (
    <>
      <header className="bg-dark py-5">
        <Container>
          <Row className="gx-5 justify-content-center">
            <Col lg={6}>
              <div className="text-center my-5">
                <h1 className="display-5 fw-bolder text-white mb-2">Contact Us</h1>
                <p className="lead text-white-50 mb-4">Do you have any questions? Please do not hesitate to
                  contact us
                  directly. Our team will come back to you within
                  a matter of hours to help you.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <section className="ftco-section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10} md={12} className="align-items-center">
              <div className="wrapper">
                <Row noGutters>
                  <Col md={12} className="d-flex align-items-stretch">
                    <div className="contact-wrap w-100 p-md-5 p-4">
                      <h3 className="mb-4">Get in touch</h3>
                      <div id="form-message-warning" className="mb-4"></div>
                      <div id="form-message-success" className="mb-4">
                        Your message was sent, thank you!
                      </div>
                      <Form id="contactForm" name="contactForm" method="POST" action="https://formsubmit.co/f7c0a3721a46201d7eea0419f6b2d22c">
                        <Row>
                          <Col md={12} className="mb-4">
                            <Form.Group controlId="name">
                              <Form.Control type="text" placeholder="Name" />
                            </Form.Group>
                          </Col>
                          <Col md={12} className="mb-4">
                            <Form.Group controlId="subject">
                              <Form.Control type="text" placeholder="Subject" />
                            </Form.Group>
                          </Col>
                          <Col md={12} className="mb-4">
                            <Form.Group controlId="message">
                              <Form.Control as="textarea" rows={7} placeholder="Message" />
                            </Form.Group>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div className="form-group">
                              <Button variant="primary" type="submit" className="text-light">Send Message</Button>
                              <div className="submitting"></div>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Contact;
