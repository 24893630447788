import React from 'react'
import { products } from '../../config/products.js'
import Card from "./Card"
import { SEO } from '../../components/SEO/SEO.jsx'
import { pages } from '../../config/rootpage.js'


const Home = () => {
  return (
    <>
    <SEO props={pages.home}/>
      <div class="pt-5"></div>

      <section class="pt-5 px-2">
        <div class="container px-lg-5">
          <div class="row gx-lg-5">
            {products.map(product => (
              <Card product={product} />
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Home