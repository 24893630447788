import { useState, useEffect } from 'react';
import PageHeader from '../../../components/Header/PageHeader';
import FilterDOM from '../../../components/FilterDOM';
import { ToastContainer } from 'react-toastify';
import BusinessGridItem from "./BusinessGridItem";
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import styled from 'styled-components';

const Controls = styled.div`
    display: flex;
    justify-content:center;
    padding-bottom: 2rem;
`
const BusinessNameGenerator = ({ type }) => {
  const [gridData, setData] = useState([]);
  const [keywords, setKeywords] = useState(null);
  const [tag, setTag] = useState(null);

  useEffect(() => {
    document.title = "Business Name Generator";
  }, []);


  const sendRequest = ({max = 50}) => {
    if(keywords !== ''){
      setData(window.StartupNameGenerator(keywords).slice(0, max))
      setTag(keywords)
    }else{
      setData([])
    }
    
  }

  function onOptionChange(optionValue) {
    sendRequest(optionValue);
  }

  function gridItem(item) {

    return (<BusinessGridItem name={item} />);
  }

  return (
    <div>
      <PageHeader tool={type} />
      <Form>

        <Controls>
          <Row >
            <Col>
              <Form.Control type="text" placeholder="Enter Keywords" value={keywords} onChange={(e) => setKeywords(e.target.value)} />
            </Col>
            <Col>
              <Button variant="dark" block onClick={sendRequest}>Generate</Button>
            </Col>
          </Row>
        </Controls>

      </Form>

      <Container><Container>
      {gridData.length > 0 ? (<h5 >Showing Results for <b>{tag}</b></h5>) : (<></>)}
      </Container></Container>
      

      <FilterDOM data={gridData} gridItem={gridItem} onOptionChange={onOptionChange} />
      <ToastContainer />
    </div>
  )
}

export default BusinessNameGenerator
