import {  useEffect, useState } from 'react';
import { Container, Row, Col, Button, Dropdown } from 'react-bootstrap';
import { BiRefresh } from 'react-icons/bi';


const SortingBar = (props) => {
  const [resultCount, setResultCount] = useState(props.items.length);
  const [selectedOption, setSelectedOption] = useState(props.dropdown[0]);
  
  const handleRefresh = () => {
    props.onOptionChange(selectedOption)
  };

  const handleOptionChange = (eventKey) => {
    setSelectedOption(eventKey);
    setResultCount(eventKey);
    props.onOptionChange(eventKey)
    // Logic to update results based on the selected option goes here
  };

  useEffect(()=>{
    setResultCount(props.items.length)
  },[props.items.length])

  return (
    <Container fluid className="filter mt-4">
      <Row className="d-flex justify-content-between align-items-center">
        <Col xs={6} sm={6} md={4}>
          {resultCount === 0 ? (
            <p className="result-count">No Results</p>
          ) : (
            <p className="result-count ">{resultCount} Results Found</p>
          )}
        </Col>
        <Col xs={6} sm={6} md={8} className="d-flex justify-content-end align-items-center">
          <Button variant="light" className="rounded-4 me-2" onClick={handleRefresh}>
            <BiRefresh className="me-2" />
            Refresh
          </Button>
          <Dropdown onSelect={handleOptionChange}>
            <Dropdown.Toggle variant="light" className="rounded-4">
              Show {selectedOption}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {props.dropdown.map(value => (
                <Dropdown.Item eventKey={value}>{value}</Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    </Container>
  )
}

export default SortingBar