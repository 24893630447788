import React from 'react';
import { Card, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ImAddressBook } from 'react-icons/im';

const Icon = styled.div`
    padding-right: 1rem;
`

const AddressGridItem = ({ name }) => {
    const showToastMessage = () => {
        toast.success('Copied To Clipboard', {
            position: toast.POSITION.BOTTOM_RIGHT,
        });
    };

    const copyToClipBoard = () => {
        navigator.clipboard.writeText(name);
        showToastMessage()
    }
  return (
    <Col>
            <Card onClick={copyToClipBoard}>

                <Card.Body>
                    <div className="d-flex align-items-start">
                        <Icon><ImAddressBook size={24} /></Icon>
                        <pre className="mb-0">{name}</pre>
                    </div>
                </Card.Body>
            </Card>

        </Col>
  )
}

export default AddressGridItem