// import { IoPerson, BsGenderMale, BsGenderFemale, MdBusiness, ImAddressBook, BsFillCreditCard2BackFill, BsCardText } from 'react-icons/all';
import { BsPerson, BsGenderMale, BsGenderFemale, BsFillCreditCard2BackFill, BsCardText,BsShieldFillCheck } from 'react-icons/bs';
import { ImAddressBook } from 'react-icons/im';
import { MdBusiness } from 'react-icons/md';
import { pages } from './rootpage';

export const products = [
    {
        "name": "Credit Card Generator",
        "action": pages.card_generator.slug,
        "description": "You Can Generate an Instantly Brand new fake Credit Card.",
        "icon": <BsFillCreditCard2BackFill />,
        "image": "/assets/vectors/credit.png"
    },
    {
        "name": "Credit Card Validator",
        "action": pages.card_validator.slug,
        "description": "You Can Generate an Instantly Brand new fake Credit Card.",
        "icon": <BsShieldFillCheck />,
        "image": "/assets/vectors/credit.png"
    },
    {
        "name": "Address Generator",
        "action": pages.address_generator.slug,
        "description": "Generate new addresses for sample data in your project.",
        "icon": <ImAddressBook />,
        "image": "/assets/vectors/map.png"
    },
    {
        "name": "Name Generator",
        "action": pages.name_generator.slug,
        "description": "The name generator for finding the perfect name for a Human.",
        "icon": <BsPerson />,
        "image": "/assets/vectors/notes.png",
        "tag": "both",
    },
    {
        "name": "Male Name Generator",
        "action": pages.male_name_generator.slug,
        "description": "The Male name generator for finding the perfect name for a Gentleman.",
        "icon": <BsGenderMale />,
        "image": "/assets/vectors/notes.png",
        "tag": "male",
    },
    {
        "name": "Female Name Generator",
        "action": pages.female_name_generator.slug,
        "description": "The Female name generator for finding the perfect name for a Women.",
        "icon": <BsGenderFemale />,
        "image": "/assets/vectors/notes.png",
        "tag": "female",
    },
    {
        "name": "Business Name Generator",
        "action": pages.business_name_generator.slug,
        "description": "Generate Fake Company name.",
        "icon": <MdBusiness />,
        "image": "/assets/vectors/company.png"
    },
    {
        "name": "Text Generator",
        "action": pages.text_generator.slug,
        "description": "Generate meaningful paragraphs for your design.",
        "icon": <BsCardText />,
        "image": "/assets/vectors/notes.png"
    }
]