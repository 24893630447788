import React from 'react';
import { Card, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdBusiness } from 'react-icons/md';

const Icon = styled.div`
    padding-right: 1rem;
`

const BusinessGridItem = ({name }) => {
    const showToastMessage = () => {
        toast.success('Copied To Clipboard', {
            position: toast.POSITION.BOTTOM_RIGHT,
        });
    };

    const copyToClipBoard = () => {
        navigator.clipboard.writeText(name);
        showToastMessage()
    }
    return (
        <Col>
        <Card onClick={copyToClipBoard}>

            <Card.Body>
                <div className="d-flex align-items-center">
                    <Icon><MdBusiness size={24} /></Icon>
                    <span className="mb-0">{name}</span>
                </div>
            </Card.Body>
        </Card>

    </Col>
    );
};

export default BusinessGridItem;
