import React from 'react'
import '../../custom.scss';
import { Link } from 'react-router-dom';

const LandingHeader = () => {
  return (
    <>
    <header class="bg-dark py-5">
        <div class="container px-5">
            <div class="row gx-5 justify-content-center">
                <div class="col-lg-6">
                    <div class="text-center my-5">
                        <h1 class="display-5 fw-bolder text-white mb-2">Generate New Personality in a whole new way</h1>
                        <p class="lead text-white-50 mb-4">Unique Name Generator is a random generator. Generate names for Persons, Businesses, generate new random
                            Addresses, fake Credit Cards, and many other exciting options!</p>
                        <div class="d-grid gap-3 d-sm-flex justify-content-sm-center">
                            <Link to="#features" className='btn btn-primary text-white btn-lg px-4 me-sm-3'>Get Started</Link>
                            <Link to="/about" className='btn btn-outline-light btn-lg px-4'>Learn More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header></>
  )
}

export default LandingHeader