import React from "react";
import { Helmet } from "react-helmet-async";
import { config } from "../../config/constants";

export function SEO({props}) {
  const url = `${config.site_url}${props.slug}`
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      <link rel="canonical" href={url} />
      {/* <meta name="author" content={props.author} /> */}
      <meta name="keywords" content={props.keywords}></meta>
      {/* End standard metadata tags */}

      {/* Facebook tags */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:image" content={props.image} />
      {/* End Facebook tags */}

      {/* Twitter tags */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={props.title} />
      <meta property="twitter:description" content={props.description} />
      <meta property="twitter:image" content={props.image} />
      {/* End Twitter tags */}
    </Helmet>
  );
}

