import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import PageHeader from '../../../components/Header/PageHeader';
import styled from 'styled-components';
import { config } from "../../../config/constants";

const Controls = styled.div`
    display: flex;
    justify-content:center;
    padding-bottom: 2rem;
`
const Center = styled.div`
  justify-content: center;
  display: flex;
  padding : 5rem;
`

function TextGenerator({ type }) {
    const [text, setText] = useState();
    const [charLimit, setCharLimit] = useState(2000);
    const [copyButton, setCopyButtonText] = useState("Copy to Clipboard")
    const [isLoading, setIsLoading] = useState(false);


      useEffect(() => {
    document.title = "Text Generator";
  }, []);


    const handleTextChange = (event) => {
        setCopyButtonText("Copy to Clipboard")
        setText(event.target.value);
    };

    const handleCharLimitChange = (event) => {
        setCharLimit(event.target.value);
    };

    const handleGenerateClick = async () => {
        setIsLoading(true);

        const response = await fetch(`${config.api_url}/text`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'same-origin',
            body: JSON.stringify({ max: 1, chars: charLimit })
        });

        const data = await response.json();
        setText(data[0]);
        setIsLoading(false);
    };



    const showToastMessage = () => {
        toast.success('Copied To Clipboard', {
            position: toast.POSITION.BOTTOM_RIGHT,
        });
    };

    const copyToClipBoard = () => {
        navigator.clipboard.writeText(text);
        setCopyButtonText("Copied !")
        showToastMessage()
    }

    return (
        <>
            <PageHeader tool={type} />
            <Container>

                <Form>
                    <Controls>
                        <Row >
                            <Col>
                                <Form.Control type="number" value={charLimit} onChange={handleCharLimitChange} placeholder='Character Limit' />
                            </Col>
                            <Col>
                                <Button variant="dark" block onClick={handleGenerateClick}>Generate</Button>
                            </Col>
                        </Row>
                    </Controls>


                    {isLoading &&
                        <Center>
                            <Spinner animation="grow" />
                        </Center>
                    }


                    {!isLoading && (

                        text && (<>
                            <Form.Control as="textarea" rows={20} value={text} onChange={handleTextChange} className='mb-4 ' placeholder='Dummy Text' />
                            <Button variant="dark" onClick={copyToClipBoard} className='mb-4 w-100'>{copyButton}</Button>
                        </>)
                    )}

                    <ToastContainer />



                </Form>
            </Container>
        </>
    );
}

export default TextGenerator;
