import React from "react";
import { Button } from "react-bootstrap";
import styled from 'styled-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CardNumber = styled.div`
    font-size: 18px;
    text-align: justify;
    letter-spacing: 4px;
    text-shadow: 0px 0px 5px white;

`
function CreditCard({props}) {

    const cc_format = (l) => {
        let result = [];
        for (let i = 0; i < l.length; i += 4) {
          result.push(l.slice(i, i + 4));
        }
        return result.join(" ");
      };
    const showToastMessage = () => {
        toast.success('Copied To Clipboard', {
            position: toast.POSITION.BOTTOM_RIGHT,
        });
    };

    const cardToString = (card) =>{
        return `Card Type - Visa \nCard Number - ${card.number} \nCVV - ${card.cvv} \nExpire Date - ${card.exp_date}\nBank Name - ${card.bank}`
    }

    const copyToClipBoard = () => {
        navigator.clipboard.writeText(cardToString(props));
        showToastMessage()
    }

    // card style
    const cardStyle = {
        width: "100%",
        height: "auto",
        borderRadius: "15px",
        // backgroundColor: "#2c3e50",
        color: "#fff",
        padding: "20px",
        margin: "0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
    };

    // card type logo
    const logoStyle = {
        width: "60px",
        height: "40px",
        objectFit: "contain"
    };

    // card details style
    const detailsStyle = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        fontSize: "14px"
    };

    // bank name style
    const bankStyle = {
        fontSize: "18px",
        fontWeight: "bold",
    };

    const cvvStyle = {
        textAlign: "left",
    }
    const expDateStyle = {
        textAlign: "right",
    }

    return (

        <Button style={cardStyle} size="lg" variant="dark" onClick={copyToClipBoard}>
            <img src={`/assets/brand/visa.png`} alt="Visa" style={logoStyle} />
            <CardNumber>{cc_format(props.number)}</CardNumber>
            <div style={detailsStyle}>
                <div style={cvvStyle}>
                    CVV <br />
                    {props.cvv}
                </div>
                <div style={expDateStyle}>
                    EXP DATE <br />
                    {props.exp_date}
                </div>
            </div>
            <div style={bankStyle}>Visa Card</div>

        </Button>
    );
}

export default CreditCard;

