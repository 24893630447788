import React from 'react'
import "../../css/inner.css"
import "../../css/style.css"
import "../../custom.scss"
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Feature = styled.div`
    box-shadow: 0 2px 15px -3px rgba(0,0,0,.07), 0 10px 20px -2px rgba(0,0,0,.04);
`

const Card = ({product}) => {
    return (
        <div class="col-lg-6 col-xxl-4 mb-5">
            <Link to={product.action}>
            <Feature>
            <div class="card bg-light border-0 h-100">
                <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                    <div class="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4"> 
                    {product.icon}</div>
                    <h2 class="fs-4 fw-bold">{product.name}</h2>
                    <p class="mb-0">{product.description}</p>

                </div>
            </div>
            </Feature>
            </Link>
        </div>
    )
}

export default Card