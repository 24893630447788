import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/Header/PageHeader";
import { ToastContainer } from "react-toastify";
import FilterDOM from "../../../components/FilterDOM";
import { config } from "../../../config/constants";
import AddressGridItem from "./AddressGridItem";
import { Container, Form, Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { countries } from "../../../config/countries";
import { addressRender } from "../../../functions/functions";
import { SEO } from "../../../components/SEO/SEO"
import { pages } from "../../../config/rootpage";

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const AddressGenerator = ({ type }) => {
  const [gridData, setData] = useState([]);
  const [isLoading, setLoadingState] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleGenerateResult = () => {
    if (selectedCountry) {
      sendRequest();
    } else {
      setData([]);
      setLoadingState(false);
    }
  };

  const sendRequest = async (max = 50) => {
    setLoadingState(true);
    const requestBody = {
      max: max,
      countryCode: selectedCountry,
    };
    fetch(`${config.api_url}/randombycountry`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: 'same-origin',
      body: JSON.stringify(requestBody),
    }).then((res) =>
      res.json().then((data) => {
        setData(data);
        setLoadingState(false);
      })
    );
  };

  useEffect(() => {
    document.title = "Free Address Generator";
  }, []);

  function onOptionChange(optionValue) {
    sendRequest(optionValue);
  }

  function gridItem(item) {
    return <AddressGridItem name={item} />;
  }

  function Emoji({ unicode }) {
    return (
      <span role="img" aria-label={unicode}>
        {String.fromCodePoint(
          ...unicode.split(" ").map((code) => parseInt(code.slice(2), 16))
        )}
      </span>
    );
  }

  return (
    <>
      <SEO props={pages.about} />
      <PageHeader tool={type} />
      <Container>
        <Container>
          <Form>
            <Center>
              <Row>
                <Col>
                  <Form.Group controlId="country">
                    <Form.Select
                      as="select"
                      value={selectedCountry ? selectedCountry.value : ""}
                      onChange={(e) => {
                        setSelectedCountry(e.target.value);
                      }}
                    >
                      <option value="">-- Select a country --</option>

                      {countries().map(([key, value]) => (
                        <option key={key} value={key}>
                          <Emoji unicode={value.emojiU} /> {value.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Button
                    variant="dark"
                    type="button"
                    onClick={handleGenerateResult}
                    disabled={isLoading}
                  >
                    {isLoading ? "Generating ..." : "Generate Result"}
                  </Button>
                </Col>
              </Row>
            </Center>
          </Form>
        </Container>
        <FilterDOM
          data={addressRender(gridData)}
          gridItem={gridItem}
          onOptionChange={onOptionChange}
          isLoading={isLoading}
        />
        <ToastContainer />
      </Container>
    </>
  );
};

export default AddressGenerator;
