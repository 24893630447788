import React from 'react';
import { Card, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Icon = styled.div`
    padding-right: 1rem;
`

const NameGridItem = ({ icon, name }) => {
    const showToastMessage = () => {
        toast.success('Copied To Clipboard', {
            position: toast.POSITION.BOTTOM_RIGHT,
        });
    };

    const copyToClipBoard = () => {
        navigator.clipboard.writeText(name);
        showToastMessage()
    }
    return (
        <Col>
            <Card onClick={copyToClipBoard}>

                <Card.Body>
                    <div className="d-flex align-items-center">
                        <Icon>{icon}</Icon>
                        <h5 className="mb-0">{name}</h5>
                    </div>
                </Card.Body>
            </Card>

        </Col>
    );
};

export default NameGridItem;
