import { useEffect, useState } from 'react';
import SortingBar from './SortingBar'
import { Container, Spinner } from 'react-bootstrap'
import GridView from './GridView'
import styled from 'styled-components'

const Body = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const Center = styled.div`
  justify-content: center;
  display: flex;
  padding :5rem;
`
const FilterDOM = (props) => {

  const [gridData, updateData] = useState(() => props.data);

  useEffect(() => {
    window.scrollTo(0, 0)
    updateData(props.data) }, [props.data])

  function optionChange(eventKey) {
    props.onOptionChange(eventKey);
    updateData(props.data);

  }

  return (
    <Container>
      <SortingBar items={gridData} onOptionChange={optionChange} dropdown={props.dropdown !== undefined && props.dropdown !== null ? props.dropdown : [50,100,200]} />
      <Body>
        {props.isLoading ? (
          <Center>
            <Spinner animation="grow" />
          </Center>
        ) : (
          <GridView items={gridData} gridItem={props.gridItem} />
        )}

      </Body>
    </Container>
  )
}

export default FilterDOM