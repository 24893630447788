import React, { useEffect } from 'react'

const Error404 = () => {

  useEffect(() => {
    document.title = "404 Not Found";
  }, []);

  return (
    <div class="container py-5 px-5">
      <h2>404</h2>
      <h1>Page Not Found</h1>
      <p>The specified file was not found on this website. Please check the URL for mistakes and try again.</p>
    </div>
  )
}

export default Error404