import React from 'react';
import { products } from '../../config/products';
import { Container, Image } from 'react-bootstrap';

const PageHeader = ({ tool }) => {
  const item = products.find((product) => product.action === tool);

  return (
    <>
      <header className="py-5">
        <div className="bg-light rounded-3 text-center">
          <Container fluid className="d-flex flex-column justify-content-center align-items-center px-lg-5">
            <Image src={item.image} className="jumper-icon mb-4" alt="Notes" />
            <h1 className="display-5 fw-bold">{item.name}</h1>
            <p className="fs-4">{item.description}.</p>
          </Container>
        </div>
      </header>
    </>
  );
};

export default PageHeader;
