import { config } from "./constants";

export const pages = {
    home: {
        title: "Home - Unique Name Generator",
        slug: "/",
        description: "Unique Name Generator is a random generator (name generator, male name, female name, company name, fake credit card generator, address, paragraph)",
        keywords: "business name generator, name generator wheel, name generator fantasy, name generator business, name generator female, name generator male, random name generator,random word generator",
        image: `${config.site_url}/assets/banner/name-generator.png`
    },
    about: {
        title: "About us",
        slug: "/about/",
        description: "About us Page for Unique Name Generator",
        keywords: "unique name generator",
        image: `${config.site_url}/assets/banner/name-generator.png`
    },
    faq: {
        title: "FAQ - Frequently asked questions",
        slug: "/faq/",
        description: "Frequently asked questions",
        keywords: "unique name generator",
        image: `${config.site_url}/assets/banner/name-generator.png`
    },
    contact: {
        title: "Contact us",
        slug: "/contact/",
        description: "Contact us page for unique name generator",
        keywords: "unique name generator",
        image: `${config.site_url}/assets/banner/name-generator.png`
    },
    privacy_policy:{
        title: "Privacy Policy",
        slug: "/privacy-policy/",
        description: "Privacy Policy Page for Unique Name Generator",
        keywords: "unique name generator",
        image: `${config.site_url}/assets/banner/name-generator.png`
    },
    name_generator:{
        title: "Name Generator",
        slug: "/name-generator/",
        description: "Random Male Name Generator tool is Awesome tool for generate random names",
        keywords: "business name generator, name generator wheel, name generator fantasy, name generator business, name generator female, name generator male, random name generator,random word generator",
        image: `${config.site_url}/assets/banner/name-generator.png`
    },
    male_name_generator:{
        title: "Male Name Generator",
        slug: "/male-name-generator/",
        description: "Using Male Name Generator you can generate names for male persons. Our aim here is to create a new name for you instantly",
        keywords: "business name generator, name generator wheel, name generator fantasy, name generator business, name generator female, name generator male, random name generator,random word generator",
        image: `${config.site_url}/assets/banner/name-generator.png`
    },
    female_name_generator:{
        title: "Female Name Generator",
        slug: "/female-name-generator/",
        description: "Female Name Generator is random female name generator tool",
        keywords: "business name generator, name generator wheel, name generator fantasy, name generator business, name generator female, name generator male, random name generator,random word generator",
        image: `${config.site_url}/assets/banner/name-generator.png`
    },
    business_name_generator:{
        title: "Business Name Generator",
        slug: "/business-name-generator/",
        description: "Business Name generator is a random business name generator",
        keywords: "business name generator, name generator wheel, name generator fantasy, name generator business, name generator female, name generator male, random name generator,random word generator",
        image: `${config.site_url}/assets/banner/name-generator.png`
    },
    address_generator:{
        title: "Address Generator",
        slug: "/address-generator/",
        description: "Fake Address Generator tool helps you generate new fake address for you",
        keywords: "business name generator, name generator wheel, name generator fantasy, name generator business, name generator female, name generator male, random name generator,random word generator",
        image: `${config.site_url}/assets/banner/name-generator.png`
    },
    card_generator:{
        title: "Credit/Debit Card Generator",
        slug: "/credit-card-generator/",
        description: "Use our fake credit generator to generate fake card number details for your website and app",
        keywords: "credit card generator,credit card numbers,fake credit card,fake address generator,fake credit card number",
        image: `${config.site_url}/assets/banner/fake-credit-card-generator.png`
    },
    card_validator:{
        title: "Free Credit Card Generator Tool | Generate Fake Card Details Instantly",
        slug: "/card-validator/",
        description: "Generate fake credit card details instantly with our free online tool! Our credit card generator lets you create random cards with names, addresses, and more. Try it now!",
        keywords: "credit card generator,credit card numbers,fake credit card,fake address generator,fake credit card number",
        image: `${config.site_url}/assets/banner/name-generator.png`
    },
    text_generator:{
        title: "Text Generator",
        slug: "/paragraph-generator/",
        description: "Use a meaningful placeholder text for your design. Unique Name Generator is a random generator (name generator, male name, female name, company name, fake credit card generator, address, paragraph)",
        keywords: "business name generator, name generator wheel, name generator fantasy, name generator business, name generator female, name generator male, random name generator,random word generator",
        image: `${config.site_url}/assets/banner/name-generator.png`
    }
}

