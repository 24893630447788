import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Link } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaEnvelope } from "react-icons/fa";
import { pages } from '../../config/rootpage';

const Section = styled.div`
    padding-top: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 4rem;   
`
const FooterSection = styled.div`
    color: ${({ theme }) => theme.darkTextColor};
    background-color: ${({ theme }) => theme.secondary};
    width: 100%;
    position: absolute;
`

const SocialLinks = styled.div`
    display: flex;
    justify-content:center;
    padding-bottom: 2rem;
`
const Footer = () => {
    return (
        <>
            <FooterSection className="mt-auto">
                <Container>
                    <Row>
                        <Col>
                            <Section>
                                <h6 class="text-uppercase fw-bold mb-4">
                                    TesKill Technologies
                                </h6>
                                <p>
                                    We are wizards of the information technology age. We are dedicated to a better future.
                                </p>
                            </Section>
                        </Col>
                        <Col>
                            <Section>
                                <h6 class="text-uppercase fw-bold mb-4">
                                    Useful links
                                </h6>
                                <p>
                                    <Link to={pages.about.slug} class="text-reset">About Us</Link>
                                </p>
                                <p>
                                    <Link to={pages.contact.slug} class="text-reset">Contact US</Link>
                                </p>
                                <p>
                                    <Link to={pages.privacy_policy.slug} class="text-reset">Privacy Policy</Link>
                                </p>
                            </Section>
                        </Col>
                        <Col>
                            <Section>
                                <h6 class="text-uppercase fw-bold mb-4">
                                    Contact
                                </h6>
                                <p>Southern , LK</p>
                                <p>

                                    teskilltechnologies@gmail.com
                                </p>
                                <p>
                                    TesKill Technologies
                                </p>
                            </Section>
                        </Col>
                    </Row>
                    <SocialLinks>
                    <Row>
                        
                            <Col>
                                <Link to="https://www.facebook.com/TeskillTechnologies" className='btn btn-outline-light btn-floating m-1 rounded-circle'><FaFacebookF /></Link>

                                <Link to="https://twitter.com/TeskillT" className='btn btn-outline-light btn-floating m-1 rounded-circle'><FaTwitter /></Link>

                                <Link to="mailto:teskilltechnologies@gmail.com" className='btn btn-outline-light btn-floating m-1 rounded-circle'><FaEnvelope /></Link>
                            </Col>
                        
                    </Row>
                    </SocialLinks>
                    <Row>
                        <div class="text-center pb-4">
                            © {new Date().getFullYear()} Copyright -
                            <Link to="https://teskill.com" className='text-decoration-none text-white' target='_blank'> TesKill Technologies</Link>
                        </div>
                    </Row>
                </Container>
            </FooterSection>
        </>

    )
}

export default Footer