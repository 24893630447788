import React, {useEffect} from 'react';
import { Accordion, Container } from 'react-bootstrap';

const faqItems = [

  {
    "question": "What is the Unique Name Generator?",
    "answer": "Unique name generator is a modern technology tool powered by AI and ML that can instantly generate a million new names."
  },
  {
    "question": "How do you create a brand name?",
    "answer": "Ideally, you want something that's: Meaningful: It communicates your logo essence, conjures an image, and cultivates a high-quality emotional connection. Distinctive: It is particular, memorable, and stands proud out of your competition. Accessible: People can without problems interpret it, say it, spell it, or Google it."
  },
  {
    "question": "How do I name my small business?",
    "answer": "Keep the name brief and sharp, this can make it more memorable. Avoid old, and dated business naming conventions (like 'for you' or 'R US') Craft a commercial enterprise name that speaks in your neighborhood customers. Small groups have the advantage of being part of and serving a network. Use this to your gain!"
  },
  {
    "question": "How does the Business Name Generator work?",
    "answer": "This unique name generator use ai technology to generate your male name, female name, company names and credit card details. ai is latest technology for generate names."
  },
  {
    "question": "Is the unique Name Generator free to use?",
    "answer": "Yes... unique name generator is world number one online name generator website. all features are free to use."
  },
  {
    "question": "How do I come up with creative Company names?",
    "answer": "Finding creative and strong company name to your web site. use key word related to your industry. just input these keyword to company name generator and you will instantly get list of creative and strong company names."
  },
  {
    "question": "How do I choose a good company name?",
    "answer": "Your company name should be unique from your competitors. Your company name also want to be quickly highlight your company target audience."
  }
];



const FAQPage = () => {

  useEffect(() => {
    document.title = "FAQ - Frequently asked questions";
    window.scrollTo(0,0)
  }, []);


  return (
    <>
      <header class="bg-dark py-5">
        <div class="container px-5">
          <div class="row gx-5 justify-content-center">
            <div class="col-lg-6">
              <div class="text-center my-5">
                <h1 class="display-5 fw-bolder text-white mb-2">Frequently asked questions</h1>
                <p class="lead text-white mb-4 mt-5">An faq page is provides answers to common questions, assuages concerns and overcomes objection in unique name generator website. in this section you can solve most common question in our website. read this content before contact our admins.
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Container className='py-5 px-3'>
        <Accordion defaultActiveKey="0">
          {faqItems.map((item, index) => (
            <Accordion.Item eventKey={index.toString()}>
              <Accordion.Header>{item.question}</Accordion.Header>
              <Accordion.Body>
                {item.answer}
              </Accordion.Body>
            </Accordion.Item>
          ))}

        </Accordion>
      </Container>
    </>

  );
}

export default FAQPage